import api from "./api";

// Utility function for file downloads
const downloadFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Error handling function
const handleError = (error) => {
  console.error("API Error:", error);
  return { error: error.response?.data?.message || "An error occurred" };
};

// User related functions
export const LoginUser = async (email, password) => {
  try {
    const response = await api.post("login", {
      Email: email,
      Password: password
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const RegisterUser = async ({
  firstName,
  lastName,
  email,
  password,
  companyId,
  isManager,
}) => {
  try {
    const response = await api.post("users", {
      firstName,
      lastName,
      email,
      password,
      companyId,
      isManager,
      userId: "",
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetUsers = async (page, sortField, sortAscending, filters) => {
  try {
    const params = {
      page,
      sortField,
      sortAscending,
    };

    // Add filters to params
    if (filters) {
      Object.keys(filters).forEach((key, index) => {
        params[`filters[${key}]`] = filters[key];
      });
    }

    const response = await api.get(`users`, { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetUser = async (id) => {
  try {
    const response = await api.get(`users/id/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdateUser = async ({
  userId,
  firstName,
  lastName,
  email,
  companyId,
  isManager,
}) => {
  try {
    const response = await api.put("users", {
      userId,
      firstName,
      lastName,
      email,
      companyId,
      isManager,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const DeleteUser = async (id) => {
  try {
    const response = await api.delete(`users/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetManagers = async () => {
  try {
    const response = await api.get("users/managers");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Profile related functions
export const GetProfile = async () => {
  try {
    const response = await api.get("profile/UpdateProfile");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdateProfile = async ({ firstName, lastName, phoneNumber }) => {
  try {
    const response = await api.put("profile/UpdateProfile", {
      firstName,
      lastName,
      phoneNumber,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdatePassword = async ({ oldPassword, newPassword }) => {
  try {
    const response = await api.post("profile/ChangePassword", {
      oldPassword,
      newPassword,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdateSetting = async ({
  companyName,
  website,
  timeZone,
  mobileToken,
}) => {
  try {
    const response = await api.put("profile/UpdateProfile", {
      companyName,
      website,
      timeZone,
      mobileToken,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GenerateMobileToken = async () => {
  try {
    const response = await api.get("profile/GenerateMobileToken");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetTimeZone = async () => {
  try {
    const response = await api.get("profile/TimeZones");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Company related functions
export const CreateCompany = async (companyData) => {
  try {
    const response = await api.post("companies", companyData);
    return response.status;
  } catch (error) {
    return handleError(error);
  }
};

//Update Company
export const UpdateCompany = async ({ companyId, name }) => {
  try {
    const response = await api.put("companies", { companyId, name });
    return response.status;
  } catch (error) {
    return handleError(error);
  }
};

export const GetCompanies = async (page, sortField, sortAscending, filters) => {
  try {
    const params = {
      page,
      sortField,
      sortAscending,
    };

    if (filters) {
      Object.keys(filters).forEach((key) => {
        params[`filters[${key}]`] = filters[key];
      });
    }

    const response = await api.get(`companies`, { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetCompany = async (id) => {
  try {
    const response = await api.get(`companies/id/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const DeleteCompany = async (id) => {
  try {
    const response = await api.delete(`companies/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetDropDown = async () => {
  try {
    const response = await api.get("companies/dropdown");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetCompanySettings = async () => {
  const response = await api.get('companies/settings');
  return response.data;
};

export const UpdateCompanySetting = async (settingKey, settingValue) => {
  try {
    const response = await api.put('companies/settings', null, {
      params: {
        settingKey,
        settingValue
      }
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Device related functions
export const CreateDevices = async ({
  name,
  deviceToken,
  companyId,
  timeZone,
}) => {
  try {
    const response = await api.post("devices", {
      name,
      deviceToken,
      companyId,
      timeZone,
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetDevices = async (page, sortField, sortAscending, filters) => {
  try {
    const params = {
      page,
      sortField,
      sortAscending,
    };

    if (filters) {
      Object.keys(filters).forEach((key) => {
        params[`filters[${key}]`] = filters[key];
      });
    }

    const response = await api.get(`devices`, { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetDevice = async (id) => {
  try {
    const response = await api.get(`devices/id/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const UpdateDevices = async ({
  deviceId,
  companyId,
  name,
  deviceToken,
  timeZone,
}) => {
  try {
    const response = await api.put("devices", {
      deviceId,
      companyId,
      name,
      deviceToken,
      timeZone,
    });
    return response.status;
  } catch (error) {
    return handleError(error);
  }
};

export const DeleteDevice = async (id) => {
  try {
    const response = await api.delete(`devices/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Result related functions
export const GetResults = async (page, sortField, sortAscending, filters) => {
  try {
    const params = {
      page,
      sortField,
      sortAscending,
    };

    if (filters) {
      Object.keys(filters).forEach((key) => {
        params[`filters[${key}]`] = filters[key];
      });
    }

    const response = await api.get(`results`, { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetResult = async (id) => {
  try {
    const response = await api.get(`results/id/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const DeleteResult = async (id) => {
  try {
    const response = await api.delete(`results/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GenerateResult = async (id) => {
  try {
    const response = await api.get(`results/download/${id}`, {
      responseType: "blob",
    });
    downloadFile(response, `result-${id}.xlsx`);
  } catch (error) {
    return handleError(error);
  }
};

export const GenerateCSVResult = async () => {
  try {
    const response = await api.get("generate/csv/results", {
      responseType: "blob",
    });
    downloadFile(response, "csv-result-list.csv");
  } catch (error) {
    return handleError(error);
  }
};

export const QueueResult = async (id, email = false, emailAddress = null) => {
  try {
    const response = await api.get(`results/queue/${id}`, {
      params: { email, emailAddress },
    });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

// Other functions
export const GetDashboard = async () => {
  try {
    const response = await api.get("dashboard");
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetQRCode = async (text, size = 10) => {
  try {
    const response = await api.get(`qr/generate/${encodeURIComponent(text)}`, {
      responseType: "blob",
    });
    return URL.createObjectURL(response.data);
  } catch (error) {
    return handleError(error);
  }
};

export const GenerateBottleBarCode = async (userId, count) => {
  try {
    const response = await api.get(
      `generate/barcode/sample/${userId}/${count}`,
      { responseType: "blob" }
    );
    downloadFile(response, "Bottle-barcode-list.csv");
  } catch (error) {
    return handleError(error);
  }
};

export const GetSamples = async (page, sortField, sortAscending, filters) => {
  try {
    const params = {
      page,
      sortField,
      sortAscending,
    };

    if (filters) {
      Object.keys(filters).forEach((key) => {
        params[`filters[${key}]`] = filters[key];
      });
    }

    const response = await api.get(`samples`, { params });
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const GetSample = async (id) => {
  try {
    const response = await api.get(`samples/id/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

export const DeleteSample = async (id) => {
  try {
    const response = await api.delete(`samples/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};
