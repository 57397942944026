import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Card, CardBody, Button, Input, Row, Col } from 'reactstrap';
import { GetCompanySettings, UpdateCompanySetting } from '../services';
import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/footer';
import company from '../img/company.png';
import { toast } from 'react-toastify';

const SettingItem = ({ setting, onUpdate }) => {
    const [value, setValue] = useState(setting.settingValue);
    const [isEditing, setIsEditing] = useState(false);

    const handleUpdate = () => {
        onUpdate(setting.settingKey, value);
        setIsEditing(false);
    };

    return (
        <Card className="setting-card mb-4 border-0 shadow-sm">
            <CardBody>
                <Row className="align-items-center">
                    <Col md={8}>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fas fa-sliders-h text-primary mr-2"></i>
                            <h5 className="mb-0 font-weight-bold">{setting.title}</h5>
                        </div>
                        <p className="text-muted mb-3">{setting.description}</p>
                        {isEditing ? (
                            <div className="d-flex align-items-center">
                                <Input
                                    type={setting.settingType}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    className="form-control-lg w-50 mr-3"
                                />
                                <Button color="success" onClick={handleUpdate} className="mr-2">
                                    <i className="fas fa-check mr-1"></i> Save
                                </Button>
                                <Button color="secondary" onClick={() => setIsEditing(false)}>
                                    <i className="fas fa-times mr-1"></i> Cancel
                                </Button>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <div className="current-value mr-3">
                                    <span className="text-muted">Current Value: </span>
                                    <span className="h5 mb-0 font-weight-bold text-primary">{value}</span>
                                </div>
                                <Button color="primary" size="sm" onClick={() => setIsEditing(true)}>
                                    <i className="fas fa-pen mr-1"></i> Edit
                                </Button>
                            </div>
                        )}
                    </Col>
                    <Col md={4} className="text-right">
                        <div className="setting-meta text-muted">
                            <small>
                                <i className="fas fa-clock mr-1"></i>
                                Last updated: {setting.lastUpdate || 'Never'}
                            </small>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

const CompanySettingsPage = () => {
    const queryClient = useQueryClient();
    const { data, isLoading } = useQuery('companySettings', GetCompanySettings);

    const updateSettingMutation = useMutation(
        ({ settingKey, settingValue }) => UpdateCompanySetting(settingKey, settingValue),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('companySettings');
                toast.success('Setting updated successfully');
            },
            onError: () => {
                toast.error('Failed to update setting');
            }
        }
    );

    const handleUpdate = (key, value) => {
        updateSettingMutation.mutate({ settingKey: key, settingValue: value });
    };

    if (isLoading) return <div className="loading-spinner"></div>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Roshan Water | Company Settings</title>
            </Helmet>
            <TopNav imageSrc={company} text="Company Settings" />
            <SideNav />
            <div className="maincontent">
                <div className="container-fluid main-content">
                    <div className="page-header mb-4">
                        <h1 className="h3 mb-2 text-gray-800">Company Settings</h1>
                        <p className="text-muted">
                            Manage your company's operational parameters and thresholds
                        </p>
                    </div>
                    <div className="settings-grid">
                        {data?.companySettings.map((setting) => (
                            <SettingItem
                                key={setting.settingKey}
                                setting={setting}
                                onUpdate={handleUpdate}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CompanySettingsPage;
