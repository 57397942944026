export const formatMapDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
};

const getTimeStatus = (sampleTime, testTime) => {
    if (!sampleTime || !testTime) return null;

    const diffHours = (new Date(testTime) - new Date(sampleTime)) / (1000 * 60 * 60);

    if (diffHours <= 8) return { color: '#1cc88a', icon: 'circle-check' };  // green
    if (diffHours <= 24) return { color: '#f6c23e', icon: 'triangle-exclamation' }; // yellow
    return { color: '#e74a3b', icon: 'circle-exclamation' };  // red
};

const popupStyles = `
  .popup-container { padding: 12px; }
  .popup-header { 
    margin: 0 0 12px 0; 
    padding-bottom: 8px; 
    border-bottom: 1px solid #e3e6f0;
    font-size: 1rem;
    font-weight: 600;
    color: #4e73df;
  }
  .popup-time {
    display: flex;
    align-items: center;
    background: #f8f9fc;
    padding: 6px 10px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  .popup-time-value {
    flex: 1;
    font-size: 0.875rem;
    color: #5a5c69;
  }
  .popup-details {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 6px 12px;
    margin-bottom: 12px;
    font-size: 0.875rem;
  }
  .popup-label {
    color: #858796;
    font-weight: 500;
  }
  .popup-value {
    color: #5a5c69;
  }
  .popup-cfu {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

const getMarkerColor = (cfuCount, greenLevel, yellowLevel) => {
    if (!cfuCount) return 'blue'; // default color for samples without results
    return cfuCount < greenLevel ? 'green' : cfuCount < yellowLevel ? 'yellow' : 'red';
};

export const createTestRecordMarker = (record, onDetailsClick) => {
    const cfuCount = record.aiPredictions?.concentration || 0;
    const markerColor = getMarkerColor(cfuCount, record.greenLevel, record.yellowLevel);
    const markerUrl = `https://maps.google.com/mapfiles/ms/icons/${markerColor}.png`;

    const detailsButton = onDetailsClick ? `
        <button 
            onclick="google.maps.customMarkers.handleDetailsClick('${record.cloudResultId}')"
            style="
                margin-top: 8px;
                padding: 6px 12px;
                background-color: #36b9cc;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                width: 100%;
            "
        >
            <i class="fas fa-info-circle fa-sm mr-1"></i>
            View Details
        </button>
    ` : '';

    return {
        position: {
            lat: parseFloat(record.sample.latitude),
            lng: parseFloat(record.sample.longitude)
        },
        icon: markerUrl,
        title: record.device.name,
        content: `
            <style>${popupStyles}</style>
            <div class="popup-container">
                <h6 class="popup-header">
                    <i class="fas fa-microscope mr-2"></i>${record.device.name}
                </h6>
                <div class="popup-time">
                    <span class="popup-time-value">
                        <i class="fas fa-clock mr-1"></i>
                        ${((new Date(record.setDateTimeLocal) - new Date(record.sample.setDateTimeUtc)) / (1000 * 60 * 60)).toFixed(1)}h
                    </span>
                    ${(() => {
                const status = getTimeStatus(record.sample.setDateTimeUtc, record.setDateTimeLocal);
                return status ? `<i class="fas fa-${status.icon}" style="color: ${status.color}"></i>` : '';
            })()}
                </div>
                <div class="popup-details">
                    <span class="popup-label">Sample:</span>
                    <span class="popup-value">${formatMapDate(record.sample.setDateTimeUtc)}</span>
                    <span class="popup-label">Test:</span>
                    <span class="popup-value">${formatMapDate(record.setDateTimeLocal)}</span>
                    <span class="popup-label">Water Type:</span>
                    <span class="popup-value">${record.waterTypeName}</span>
                    <span class="popup-label">Count:</span>
                    <span class="popup-value">
                        ${record.aiPredictions?.concentration}
                        <span class="popup-cfu" style="background: ${record.aiPredictions?.concentration < 150 ? '#e8f5e9' : '#fce4ec'}; color: ${record.aiPredictions?.concentration < 150 ? '#2e7d32' : '#c2185b'}">
                            CFU/100ml
                        </span>
                    </span>
                </div>
                ${detailsButton}
            </div>
        `
    };
};

export const createSampleMarker = (sample, aiPredictions, thresholds = {}) => {
    const cfuCount = aiPredictions?.concentration || 0;
    const markerColor = getMarkerColor(cfuCount, thresholds.greenLevel, thresholds.yellowLevel);
    const markerUrl = `https://maps.google.com/mapfiles/ms/icons/${markerColor}.png`;

    return {
        position: {
            lat: parseFloat(sample.latitude),
            lng: parseFloat(sample.longitude)
        },
        icon: markerUrl,
        title: 'Sample Location',
        content: `
            <style>${popupStyles}</style>
            <div class="popup-container">
                <h6 class="popup-header">
                    <i class="fas fa-map-marker-alt mr-2"></i>Sample Location
                </h6>
                <div class="popup-time">
                    <span class="popup-time-value">
                        <i class="fas fa-clock mr-1"></i>
                        ${((new Date() - new Date(sample.setDateTimeUtc)) / (1000 * 60 * 60)).toFixed(1)}h ago
                    </span>
                </div>
                <div class="popup-details">
                    <span class="popup-label">Sample Date:</span>
                    <span class="popup-value">${formatMapDate(sample.setDateTimeUtc)}</span>
                    <span class="popup-label">Water Type:</span>
                    <span class="popup-value">${sample.waterType}</span>
                    <span class="popup-label">Collection Site:</span>
                    <span class="popup-value">${sample.collectionsSite}</span>
                    <span class="popup-label">Count:</span>
                    <span class="popup-value">
                        ${aiPredictions?.concentration}
                        <span class="popup-cfu" style="background: ${aiPredictions?.concentration < 150 ? '#e8f5e9' : '#fce4ec'}; color: ${aiPredictions?.concentration < 150 ? '#2e7d32' : '#c2185b'}">
                            CFU/100ml
                        </span>
                    </span>
                </div>
            </div>
        `
    };
};

export const createSampleListMarker = (sample, onDetailsClick) => {
    // Keep blue for samples since they don't have results yet
    const markerUrl = `https://maps.google.com/mapfiles/ms/icons/blue.png`;

    const detailsButton = onDetailsClick ? `
        <button 
            onclick="google.maps.customMarkers.handleDetailsClick('${sample.id}')"
            style="
                margin-top: 8px;
                padding: 6px 12px;
                background-color: #36b9cc;
                color: white;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                width: 100%;
            "
        >
            <i class="fas fa-info-circle fa-sm mr-1"></i>
            View Details
        </button>
    ` : '';

    return {
        position: {
            lat: parseFloat(sample.latitude),
            lng: parseFloat(sample.longitude)
        },
        icon: markerUrl,
        title: sample.collectionsSite,
        content: `
            <style>${popupStyles}</style>
            <div class="popup-container">
                <h6 class="popup-header">
                    <i class="fas fa-map-marker-alt mr-2"></i>${sample.collectionsSite}
                </h6>
                <div class="popup-time">
                    <span class="popup-time-value">
                        <i class="fas fa-clock mr-1"></i>
                        ${((new Date() - new Date(sample.setDateTimeUtc)) / (1000 * 60 * 60)).toFixed(1)}h ago
                    </span>
                </div>
                <div class="popup-details">
                    <span class="popup-label">Sample ID:</span>
                    <span class="popup-value">${sample.sampleId}</span>
                    <span class="popup-label">Water Type:</span>
                    <span class="popup-value">${sample.waterType}</span>
                    <span class="popup-label">Date/Time:</span>
                    <span class="popup-value">${formatMapDate(sample.setDateTimeUtc)}</span>
                    <span class="popup-label">Residual Chlorine:</span>
                    <span class="popup-value">${sample.residualChlorine}</span>
                </div>
                ${detailsButton}
            </div>
        `
    };
};