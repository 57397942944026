import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const version = process.env.REACT_APP_VERSION || 'dev';
  
  return (
    <footer className="sticky-footer bg-white copyright">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>Copyright &copy; Roshan Water Solutions {currentYear} | v{version}</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
